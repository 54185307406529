
import { computed, defineComponent } from 'vue'

import StepContainer from '@/components/LdStepper/StepContainer/StepContainer.vue'
import StepHeader from '@/components/LdStepper/StepHeader/StepHeader.vue'
import StepFooter from '@/components/LdStepper/StepFooter/StepFooter.vue'
import LdInput from '@/components/LdInput/LdInput.vue'
import * as yup from 'yup'
import { useI18n } from 'vue-i18n'
import { useForm } from 'vee-validate'
import { propertyID } from '@/lib/validators'
import { getStoreHash } from '@/lib/utilities'
import { useStore } from 'vuex'
import { Steps } from '@/views/connection/Connection.vue'
import { sendPageViewEvent, sendBehaviorEvent, AppVirtualUrls } from '@/services/segmentTracking'

enum PROPERTY_FORM_FIELDS {
	webProperty = 'webProperty',
}

export default defineComponent({
	components: {
		StepContainer,
		StepHeader,
		StepFooter,
		LdInput,
	},
	emits: ['nextPage'],
	setup(props, { emit }) {
		const { t, tm } = useI18n()
		const store = useStore()
		const points = tm('connection.steps.property.points') as any
		const schema = yup.object({
			webProperty: yup
				.string()
				.matches(propertyID, t('connection.steps.property.webProperty.error'))
				.required(t('common.required')),
		})
		const { meta, validate, values } = useForm({
			validationSchema: schema,
			validateOnMount: false,
		})
		const userData = computed(() => store.getters['user/me/getState'])
		const storeHash = getStoreHash(userData.value.data.store)
		const onNextPage = async () => {
			const { valid } = await validate()

			sendBehaviorEvent({
				locationTitle: t('connection.steps.property.title'),
				virtualUrl: AppVirtualUrls.gaPropertyId,
				fieldTitle: t('connection.steps.property.webProperty.label'),
				actionId: 'webProperty',
				actionValue: t('common.continue'),
				actionType: 'button',
			})

			if (valid) {
				store.commit('connection/setWebPropertyId', values.webProperty!)
				emit('nextPage', Steps.NATIVE_GA)
			}
		}

		return {
			onNextPage,
			PROPERTY_FORM_FIELDS,
			meta,
			points,
			storeHash,
		}
	},
	mounted() {
		const { t } = useI18n()

		sendPageViewEvent({
			locationTitle: t('connection.steps.property.title'),
			virtualUrl: 'ga-property-id',
		})

		const { listItems } = this.$refs as any

		// attach a click event to the first item from the list
		listItems[0].addEventListener('click', () => {
			sendBehaviorEvent({
				locationTitle: t('connection.steps.property.title'),
				virtualUrl: 'ga-property-id',
				fieldTitle: 'BigCommerce Data Solution admin',
				actionId: '',
				actionValue: listItems[0].getElementsByTagName('a')[0].attributes.href.value,
				actionType: 'link',
			})
		})
	},
})
